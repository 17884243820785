<template>
    <div class="main" v-loading="loading">
        <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabList" :key="index">
                    <div class="listData" v-if="list.list.length > 0">

                        <!-- 已发布 -->
                        <div class="every_data" v-for="(item, index) in list.list" :key="index">

                            <div class="flex_between">

                                <div class="data_title">
                                    <span>【仓库求租需求】</span>{{ item.title }}
                                </div>

                                <div class="data_mark1"
                                    v-if="item.rentxinxiStatus == 1 && item.rentStatus != 1 && item.rentStatus != 3">
                                    <span>已发布</span>
                                </div>
                                <div class="data_mark1"
                                    v-if="item.rentxinxiStatus == 2 && item.rentStatus != 1 && item.rentStatus != 3">

                                    <span>已下架</span>

                                </div>
                                <div class="data_mark1" v-if="item.rentStatus == 1">

                                    <span>审核中</span>
                                </div>
                                <div class="data_mark1" v-if="item.rentStatus == 3">
                                    <span>审核失败</span>
                                </div>
                            </div>
                            <div class="flex_between" style="flex-wrap: wrap;">
                                <div class="data_list flex_center_a_w">
                                    <div class="every_list">

                                        求租人：{{ item.seekRenterName }}
                                    </div>
                                    <div class="every_list">

                                        起租日期：{{ item.rentBeginTime }}
                                    </div>
                                    <div class="every_list ellipsis_1">

                                        仓库类型：<span v-for="(items, indexs) in item.ypWarehouseTypeArr" :key="indexs">{{
                                            items.itemName }}<span class="everyContents"
                                                v-if="indexs < item.ypWarehouseTypeArr.length - 1">、</span></span>
                                    </div>
                                    <!-- <div class="every_list">
                                        求租面积：
                                        {{ item.areaMin }}~{{ item.areaMax }}㎡

                                    </div> -->
                                    <div class="every_list" v-if="item.areaMax != 100 && item.areaMin != 500">
                                        求租面积：{{ item.areaMin }}~{{ item.areaMax }}㎡
                                    </div>
                                    <div class="every_list" v-if="item.areaMin == 500">
                                        求租面积：500㎡以上
                                    </div>
                                    <div class="every_list" v-if="item.areaMax == 100">
                                        求租面积：100㎡以下
                                    </div>

                                </div>
                                <div class="data_btn flex_between"
                                    v-if="item.rentxinxiStatus == 1 && item.rentStatus != 1 && item.rentStatus != 3">

                                    <div class="btns flex_between">

                                        <div class="btn1 btn" @click="editRent(item.id)">
                                            编辑
                                        </div>

                                        <div class="btn2 btn" @click="xiajia(item.id, 2)">
                                            下架
                                        </div>
                                        <div class="btn3 btn" @click="shanchu(item.id)">

                                            删除
                                        </div>
                                    </div>

                                </div>
                                <div class="data_btn flex_between"
                                    v-if="item.rentxinxiStatus == 2 && item.rentStatus != 1 && item.rentStatus != 3">


                                    <div class="btns flex_between">
                                        <div class="btn1 btn" @click="editRent(item.id)">
                                            编辑
                                        </div>

                                        <div class="btn2 btn" @click="xiajia(item.id, 1)">
                                            上架
                                        </div>
                                        <div class="btn3 btn" @click="shanchu(item.id)">
                                            删除
                                        </div>
                                    </div>
                                </div>
                                <div class="data_btn flex_between" v-if="item.rentStatus == 1">
                                    <div class="btns flex_between">
                                        <div class="btn2 btn" @click="goAudit(1, item.id, item.failureReason)">
                                            审核详情
                                        </div>

                                    </div>

                                </div>

                                <div class="data_btn flex_between" v-if="item.rentStatus == 3">

                                    <div class="btns flex_between">

                                        <div class="btn2 btn" @click="goAudit(0, item.id, item.failureReason)">
                                            审核详情
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>



                    </div>
                    <div v-else>
                        <el-empty :image-size="200"></el-empty>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="flag" style="position: relative;">
            <!-- 分页器 -->
            <div class="items"
                style="padding-bottom: 50px; margin-top: 50px;display: flex;justify-content: center;position: absolute;right: 0;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage4" :page-sizes="[10]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="list.count">
                </el-pagination>
            </div>
        </div>
        <div style="height: 200px;">

        </div>
        <div class="popup popup2">
            <!-- <el-dialog top="160px" title="" :visible.sync="fabuEdit" width="60%" :before-close="handleClose"> -->
            <el-dialog top="160px" :close-on-click-modal="false" class="my-dialog" title="求租信息发布" :visible.sync="fabuEdit"
                width="900px" center :before-close="handleClose">
                <div class="form_con" style="width:840px; margin: 0 auto;" @click="colseAll">
                    <el-form ref="form" :model="form" label-width="120px" label-position="right">
                        <!-- <el-form ref="form" :model="param" label-width="120px" label-position="right"></el-form> -->
                        <el-form-item label="基本信息">
                        </el-form-item>
                        <div class="form_row">
                            <el-form-item label="标题">
                                <el-input v-model="param.title" :readonly="true" placeholder="无需填写"
                                    style="width: 546px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form_row">
                            <el-form-item label="求租面积">
                                <el-select @change='changeAreaMin' v-model="qiuzumianji" style="width: 208px;"
                                    placeholder="请选择">
                                    <el-option v-for="item in areaList" :key="item.type" :label="item.name"
                                        :value="item.type">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="every_form" label="求租地区">
                                <el-cascader :props="props" @change="changeFn"></el-cascader>
                                <div v-if="show1" style="position:absolute;color:#606266;font-size:10px;left:10px;top:10px;background-color: #FFFFFF;line-height: 1.3em;font-size: 16px;width: 160px;display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;">
                                    {{ `${addressValue}/${cityValue}` }}
                                </div>
                                <!-- <el-cascader v-model="qiuzudiqu" :options="optionsQz"></el-cascader> -->
                            </el-form-item>

                        </div>
                        <div class="form_row">

                            <el-form-item class="every_form" label="起租日期">
                                <el-date-picker style="width: 208px;" v-model="param.rentBeginTime" type="date"
                                    placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>

                        </div>
                        <div style="width: 80%; border-bottom:1px solid #CCCCCC;margin:50px auto"></div>
                        <div class="form_row">
                            <el-form-item label="求租方信息">
                            </el-form-item>
                        </div>

                        <div class="form_row">
                            <el-form-item class="every_form" label="公司名称">
                                <el-input v-model="param.companyName" class="form_inp" placeholder="请输入"
                                    style="width: 208px;"></el-input>
                            </el-form-item>
                            <el-form-item class="every_form" label="求租人">
                                <el-input v-model="param.seekRenterName" class="form_inp" placeholder="请输入"
                                    style="width: 208px;"></el-input>
                            </el-form-item>
                        </div>

                        <div class="form_row">
                            <el-form-item class="every_form" label="联系方式">
                                <el-input v-model="param.seekRenterContact" autocomplete="off"
                                    oninput="value=value.replace(/[^0-9]/g,'')" maxlength="11" lass="form_inp"
                                    placeholder="请输入" style="width: 208px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="width: 80%; border-bottom:1px solid #CCCCCC;margin:50px auto"></div>
                        <div class="form_row">
                            <el-form-item label="求租方需求">
                            </el-form-item>
                        </div>

                        <div class="form_row">
                            <el-form-item class="every_form" label="预计租期">
                                <el-select style="width: 208px;" v-model="param.rentTerm" placeholder="请选择">
                                    <el-option v-for="item in datePlan" :key="item.names" :label="item.name"
                                        :value="item.names">
                                    </el-option>
                                </el-select>

                            </el-form-item>
                            <el-form-item class="every_form" label="仓库用途">
                                <el-input v-model="param.ypWarehouseUseTo" lass="form_inp" placeholder="例如：存放货物"
                                    style="width: 208px;"></el-input>

                            </el-form-item>


                        </div>

                        <!-- <div class="form_row">

                            <el-form-item class="every_form" label="仓库类型">

                                <el-select :multiple-limit="4" @change="changeHouse"
                                    style="position: absolute;top: 0;z-index: 0;width: 208px;" v-model="houseSelect"
                                    multiple placeholder="请选择(最多四个)">
                                    <el-option v-for="item in selectAll.warehouse_extra" :key="item.tagId"
                                        :label="item.itemName" :value="item.tagId">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                        </div> -->

                        <div class="form_row">

                            <el-form-item class="every_form" style="width: 100%;" label="仓库类型">
                                <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                    <span @click.stop="cklx1 = !cklx1" style="cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;">选择</span>
                                    <div v-if="cklx1"
                                        style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                        <el-select :multiple-limit="4" @change="changeHouse"
                                            style="position: absolute;top: 0;z-index: 0;width: 208px;" v-model="houseSelect"
                                            multiple placeholder="请选择(最多四个)">
                                            <el-option v-for="item in selectAll.warehouse_extra" :key="item.tagId"
                                                :label="item.itemName" :value="item.tagId">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div style="width: calc(100% - 50px);">
                                        <div style="display: flex;flex-wrap:wrap;">
                                            <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                v-for="(item, index) in typeSelectList1" :key="index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </el-form-item>

                        </div>
























                        <!-- <div class="form_row">
                            <el-form-item class="every_form" label="货物类型">
                                <el-select :multiple-limit="4" style="width: 208px;" @change="changeCategory"
                                    v-model="typeSelect" multiple placeholder="请选择(最多四个)">
                                    <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                        :label="item.categoryName" :value="item.categoryId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div> -->

                        <div class="form_row">

                            <el-form-item class="every_form" style="width: 100%;" label="存放品类">
                                <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                    <span @click.stop="cklx2 = !cklx2" style="cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;">选择</span>
                                    <div v-if="cklx2"
                                        style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                        <el-select :multiple-limit="4" style="width: 208px;" @change="changeCategory"
                                            v-model="typeSelect" multiple placeholder="请选择(最多四个)">
                                            <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                                :label="item.categoryName" :value="item.categoryId">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div style="width: calc(100% - 50px);">
                                        <div style="display: flex;flex-wrap:wrap;">
                                            <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                v-for="(item, index) in typeSelectList2" :key="index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </el-form-item>

                        </div>














                        <div style="width: 80%; border-bottom:1px solid #CCCCCC;margin:50px auto"></div>


                        <div style="padding-left:50px">
                            <el-form-item label="其他内容">
                                <el-input type="textarea" v-model="param.other" style="width: 540px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form_row" style="padding:50px 0;text-align:center;">
                            <div style="width: 400px;margin:0 auto;line-height: 44px;cursor: pointer;" class="btnQueren"
                                @click.stop="submit">
                                确认发布</div>
                        </div>

                    </el-form>

                </div>
            </el-dialog>
        </div>
        <div class="xiajia">
            <el-dialog top="160px" title="下架提醒" :visible.sync="showXiajia" width="700px" center>
                <div style="text-align:center;font-size: 18px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #999999;">确定要下架该条求租信息吗，下架后系统会为您保存三个月</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showXiajia = false">取消</el-button>
                    <el-button type="primary" @click="xiajiaYes">确定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="xiajia">
            <el-dialog top="160px" title="删除提醒" :visible.sync="showDelete" width="700px" center>

                <div style="text-align:center;font-size: 18px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #999999;">确定要删除该条求租信息吗</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showDelete = false">取消</el-button>
                    <el-button type="primary" @click="deleteYes">确定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="shenhe">
            <el-dialog top="160px" title="仓库求租信息发布" :visible.sync="shenhe" width="700px" center>
                <div class="shenheimg">
                    <img v-if="type == 1" src="@/assets/person/shenhe.png" alt="">
                    <img v-if="type == 0" src="@/assets/person/shenheno.png" alt="">
                </div>
                <div class="tishi">
                    <div v-if="type == 1"> 后台审核中，请耐心等待</div>
                    <div v-if="type == 1">我们会在24小时内给您反馈</div>
                    <div v-if="type == 0">审核失败，请更正后重新提交</div>
                </div>
                <div class="reason" v-if="type == 0">
                    {{ reason }}
                </div>

                <div class="buttn" @click="shenhe = false" v-if="type == 1">
                    我知道了
                </div>
                <div class="buttn" v-if="type == 0" @click="xiugaiShenhe">
                    去修改
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>


export default {
    data() {
        let that = this;
        return {
            // optionsQz: [{
            //     value: 'zhinan',
            //     label: '指南',
            //     children: [{
            //         value: 'shejiyuanze',
            //         label: '设计原则',
            //         children: [{
            //             value: 'yizhi',
            //             label: '一致'
            //         }, {
            //             value: 'fankui',
            //             label: '反馈'
            //         }, {
            //             value: 'xiaolv',
            //             label: '效率'
            //         }, {
            //             value: 'kekong',
            //             label: '可控'
            //         }]
            //     }, {
            //         value: 'daohang',
            //         label: '导航',
            //         children: [{
            //             value: 'cexiangdaohang',
            //             label: '侧向导航'
            //         }, {
            //             value: 'dingbudaohang',
            //             label: '顶部导航'
            //         }]
            //     }]
            // }],

            warehouse_extras: '',
            loading: false,
            show1: false,
            props: {
                lazy: true,
                label: 'name', value: 'id',
                lazyLoad: async (node, resolve) => {
                    // console.log(node, resolve)
                    const { level } = node;
                    console.log(level);
                    if (level >= 1) {
                        if (level == 3) {
                            return
                        }
                        let data = await this.getArea(node.value) //[{ id: 1, name: 222 }]//
                        let res = data.map(i => {
                            i.leaf = level >= 1
                            return i
                        })
                        resolve(res);
                    } else {

                        resolve(this.addressList);
                    }




                }
            },
            cklx1: false,
            cklx2: false,
            typeSelectList1: [],
            typeSelectList2: [],
            shenheId: '',
            type: 1,
            reason: '',
            shenhe: false,
            addressList: '',
            cityList: '',
            areasList: '',
            addressValue: '',
            cityValue: '',
            areaValue: '',
            fabuEdit: false,
            activeName: 'first',
            qiuzudiqu: [],
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            activeName: 'first',
            tabList: [
                {
                    label: "全部",
                    name: "first",
                },
                {
                    label: "已发布",
                    name: "second",
                },
                {
                    label: "已下架",
                    name: "third",
                },
                {
                    label: "审核中",
                    name: "fourth",
                },
                {
                    label: "审核失败",
                    name: "fifth",
                }
            ],
            dateStart: '',
            datePlan: [{
                names: 1,
                name: "1年"
            }, {
                names: 2,
                name: "2年"
            }, {
                names: 3,
                name: "3年"
            }, {
                names: 4,
                name: "4年"
            }, {
                names: 5,
                name: "5年"
            }, {
                names: 6,
                name: "6年"
            }, {
                names: 7,
                name: "7年"
            }, {
                names: 8,
                name: "8年"
            }, {
                names: 9,
                name: "9年"
            }, {
                names: 10,
                name: "10年"
            },
            {
                names: 11,
                name: "11年"
            }, {
                names: 12,
                name: "12年"
            }, {
                names: 13,
                name: "13年"
            }, {
                names: 14,
                name: "14年"
            }, {
                names: 15,
                name: "15年"
            }, {
                names: 16,
                name: "16年"
            }, {
                names: 17,
                name: "17年"
            }, {
                names: 18,
                name: "18年"
            }, {
                names: 19,
                name: "19年"
            }, {
                names: 20,
                name: "20年"
            }
            ],
            planIndex: -1,
            areaIndex: -1,
            areaList: [{
                max: 100,
                min: 0,
                name: "100m²以下",
                type: 0
            }, {
                max: 200,
                min: 100,
                name: "100-200m²",
                type: 1
            }, {
                max: 300,
                min: 200,
                name: "200-300m²",
                type: 2
            }, {
                max: 400,
                min: 300,
                name: "300-400m²",
                type: 3
            }, {
                max: 500,
                min: 400,
                name: "400-500m²",
                type: 4
            }, {
                max: 9999,
                min: 500,
                name: "500m²以上",
                type: 5
            }],
            qiuzumianji: '',
            areaIndex1: 0,
            areaList1: [],
            multiArray: [],
            typeIndex: 0,
            typeList: [],
            typeSelect: [],
            useSelect: [],
            typeFlag: true,
            useIndex: 0,
            useList: [],
            houseIndex: 0,
            houseList: [],
            showReturn: false,
            param: {
                opType: 1,
                title: '', //求租标题
                areaMax: '', //面积最大值
                areaMin: '', //面积最小值
                provinceId: '', //省id
                cityId: '', //市id
                rentBeginTime: '', //起租日期
                companyName: '', //公司名称
                seekRenterName: '', //求租人姓名
                seekRenterContact: '', //求租人的联系方式
                rentTerm: '', //预计租期
                ypThingType: '', //货物类型 多选 品类
                ypWarehouseUseTo: '', //仓库用途 多选
                ypWarehouseType: '', //仓库的类型 单选
                other: '', //其他内容


            },
            houseSelect: [],
            rule: [{
                name: "title",
                checkType: "isNotNull",
                errorMsg: "请输入标题"
            },
            {
                name: "areaMin",
                checkType: "isNotNull",
                errorMsg: "请选择最小面积"
            },
            {
                name: "areaMax",
                checkType: "isNotNull",
                errorMsg: "请选择最大面积"
            },
            {
                name: "provinceId",
                checkType: "isNotNull",
                errorMsg: "请选择地址"
            },
            {
                name: "cityId",
                checkType: "isNotNull",
                errorMsg: "请选择地址"
            },
            {
                name: "rentBeginTime",
                checkType: "isNotNull",
                errorMsg: "请选择起租日期"
            },
            {
                name: "companyName",
                checkType: "isNotNull",
                errorMsg: "请输入公司名称"
            },
            {
                name: "seekRenterName",
                checkType: "isNotNull",
                errorMsg: "请输入求租人姓名"
            },
            {
                name: "seekRenterContact",
                checkType: "isNotNull",
                errorMsg: "请输入联系方式"
            },
            {
                name: "rentTerm",
                checkType: "isNotNull",
                errorMsg: "请选择预计租期"
            },
            {
                name: "ypThingType",
                checkType: "isNotNull",
                errorMsg: "请选择存放品类"
            },
            {
                name: "ypWarehouseUseTo",
                checkType: "isNotNull",
                errorMsg: "请选择仓库用途"
            },
            {
                name: "ypWarehouseType",
                checkType: "isNotNull",
                errorMsg: "请选择仓库类型"
            }
            ],
            addressList: [],
            select: '',
            arr1: [],
            arr2: [],
            selectAddress: '', //省市区拼接
            selectAll: {},
            warehouse_extra: '',
            options: {},
            detailParam: {
                type: 2,
                viewType: 1,
                id: ''
            },
            showXiajia: false,
            showDelete: false,
            paramList: {
                type: 2,
                status: 0,
                upDown: 0,
                // uid: '',
                page: 1,
                viewType: 1
            },
            list: {
                list: []
            },
            form: {
                id: '',
                opType: 3,
                rentxinxiStatus: ''
            },
            deleteParam: {
                id: '',
                opType: 4,
            },
            currentPage4: 1,
            titleArea: '',
            titleAddress: '',
            flag: true
        };
    },

    mounted() {

    },
    created() {
        this.rentList()
        this.getAreaSheng()
        this.getSelect()

    },
    methods: {
        colseAll() {
            this.cklx1 = false
            this.cklx2 = false
        },
        changeAreaMin(e) {
            console.log(this.areaList[e]);
            this.param.areaMin = this.areaList[e].min
            this.param.areaMax = this.areaList[e].max
            this.titleArea = this.areaList[e].name
            console.log(this.titleAddress, this.titleArea);
            if (this.titleArea != '' && this.titleAddress != '') {
                this.param.title = this.titleAddress + this.titleArea
            }

        },
        async changeFn(e) {
            this.show1 = false
            console.log(this.qiuzudiqu);
            console.log(333, e);
            this.param.provinceId = e[0]
            this.param.cityId = e[1]
            let pro = ''
            let city = ''
            let data = await this.getArea(e[0])
            console.log(data);
            let cityList = data
            for (let i = 0; i < this.addressList.length; i++) {
                if (this.addressList[i].id == e[0]) {
                    pro = this.addressList[i].name

                }

            }

            for (let i = 0; i < cityList.length; i++) {
                if (cityList[i].id == e[1]) {
                    city = cityList[i].name
                }
            }
            this.titleAddress = pro + city
            console.log(this.titleAddress, this.titleArea);
            if (this.titleArea != '' && this.titleAddress != '') {
                this.param.title = this.titleAddress + this.titleArea

            }


        },
        goAudit(type, id, reason) {
            this.type = type
            this.reason = reason
            this.shenheId = id
            this.shenhe = true

        },
        xiugaiShenhe() {
            this.editRent(this.shenheId)
        },
        async submit() {
            console.log(this.param);
            let data = await this.$api.base.askForRent(this.param)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                    offset: 100
                });
                this.fabuEdit = false
                this.type = 1
                this.shenhe = true
                this.rentList()
                nav.classList.remove("fixedNav");

            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }

            // this.$util.navTo("pages/information/audit")
        },
        changeUse(e) {
            this.param.ypWarehouseUseTo = e.join(",")
        },
        changeHouse(e) {
            console.log(e);
            this.param.ypWarehouseType = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.warehouse_extra.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.warehouse_extra[i].tagId == e[j]) {
                        arr[arr.length] = this.selectAll.warehouse_extra[i].itemName
                    }
                }


            }
            this.typeSelectList1 = arr
        },
        // 仓库类型
        // changeType(e) {
        //     console.log(e)
        //     // this.warehouse_extra = this.selectAll.warehouse_extra[e.detail.value].itemName
        //     this.param.warehouseType = e.join(",")
        // },
        changeCategory(e) {
            console.log(e);
            this.param.ypThingType = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.common_category.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.common_category[i].categoryId == e[j]) {
                        arr[arr.length] = this.selectAll.common_category[i].categoryName
                    }
                }


            }
            this.typeSelectList2 = arr
        },
        async changeSheng(e) {
            this.param.provinceId = e
            this.cityList = await this.getArea(e)

        },
        async changeShi(e) {
            this.param.cityId = e
            this.areasList = await this.getArea(e)

        },
        async changeQu(e) {
            // this.param.county = e


        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.paramList.page = val
            this.rentList()
        },
        handleClick(tab, event) {
            this.flag = false
            setTimeout(() => {
                this.currentPage4 = 1
                this.flag = true
            }, 500)



            this.activeName = tab.name
            console.log(tab.index, tab.name);
            this.paramList.page = 1

            if (tab.index == 0) {
                this.paramList.status = 0
                this.paramList.upDown = 0
            } else if (tab.index == 1) {
                this.paramList.status = 2
                this.paramList.upDown = 1
            } else if (tab.index == 2) {
                this.paramList.status = 0
                this.paramList.upDown = 2
            } else if (tab.index == 3) {
                this.paramList.status = 1
                this.paramList.upDown = 0
            } else if (tab.index == 4) {
                this.paramList.status = 3
                this.paramList.upDown = 0
            }

            this.rentList()
        },

        // 编辑
        editRent(id) {
            this.detailParam.id = id
            this.rentDetail()
            this.fabuEdit = true
            nav.classList.add("fixedNav");
        },
        async rentList() {
            let {
                paramList
            } = this;
            this.loading = true
            let newList = await this.$api.base.rentList(paramList);
            console.log(newList);
            for (var i = 0; i < newList.list.length; i++) {
                newList.list[i].rentBeginTime = this.$util.formatTime(parseInt(newList.list[i].rentBeginTime), "YY-M-D")
            }
            this.list = newList
            this.loading = false
        },
        async rentDetail() {
            this.show1 = true
            let data = await this.$api.base.rentDetail(this.detailParam)
            console.log(data);
            if (data.code == -9999) {
                this.loading = false
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });

                return
            }
            this.param.opType = 2
            this.param.id = this.detailParam.id
            this.param.title = data.info.title

            this.param.areaMax = data.info.areaMax
            this.param.areaMin = data.info.areaMin
            this.qiuzumianji = data.info.areaMin + "-" + data.info.areaMax + '㎡'
            if (data.info.areaMin == 500) {
                this.qiuzumianji = '500㎡以上'
            } else {
                this.qiuzumianji = data.info.areaMin + "-" + data.info.areaMax + '㎡'
            }
            if (data.info.areaMax == 100) {
                this.qiuzumianji = '100㎡以下'
            } else {
                this.qiuzumianji = data.info.areaMin + "-" + data.info.areaMax + '㎡'
            }
            this.param.provinceId = data.info.provinceId
            let arrCity = data.info.proCity.split("-")
            console.log(arrCity);
            this.addressValue = arrCity[0]
            this.cityValue = arrCity[1]
            this.qiuzudiqu = arrCity
            this.param.cityId = data.info.cityId
            this.selectAddress = data.info.proCity
            let arrss = data.info.proCity.split("-")
            this.titleAddress = arrss[0] + arrss[1]
            console.log(this.titleAddress);
            this.param.rentBeginTime = this.$util.formatTime(data.info.rentBeginTime * 1, "YY-M-D")
            console.log(this.param.rentBeginTime);
            this.dateStart = this.$util.formatTime(data.info.rentBeginTime * 1, "YY-M-D")
            this.param.companyName = data.info.companyName
            this.param.seekRenterName = data.info.seekRenterName
            this.param.seekRenterContact = data.info.seekRenterContact
            this.param.rentTerm = data.info.rentTerm
            this.param.ypWarehouseUseTo = data.info.ypWarehouseUseTo
            this.useSelect = data.info.ypWarehouseUseTo.split(",")
            this.param.ypWarehouseType = data.info.ypWarehouseType
            this.warehouse_extras = data.info.ypWarehouseTypeStr
            this.houseSelect = data.info.ypWarehouseType.split(",")

            // let datas = data.info.ypWarehouseType.split(",")
            // let arra = []
            // for (let i = 0; i < datas.length; i++) {
            //     arra[arra.length] = datas[i]

            // }
            console.log(this.houseSelect);


            this.param.other = data.info.other

            // for (let i = 0; i < data.info.categoryArr.length; i++) {
            //     this.typeSelect[this.typeSelect.length] = data.info.categoryArr[i].categoryId
            // }
            let arr = []
            let arr1 = []

            for (var i = 0; i < data.info.categoryArr.length; i++) {
                arr[arr.length] = data.info.categoryArr[i].categoryId
                arr1[arr1.length] = data.info.categoryArr[i].categoryName
            }
            let arr2 = []
            for (var i = 0; i < data.info.ypWarehouseTypeArr.length; i++) {

                arr2[arr2.length] = data.info.ypWarehouseTypeArr[i].itemName
            }
            this.typeSelect = arr
            this.typeSelectList2 = arr1
            this.typeSelectList1 = arr2
            console.log(arr1);
            console.log(this.typeSelect);




            // let arr = []
            // for (let i = 0; i < this.selectAll.common_category.length; i++) {
            //     for (let j = 0; j < e.length; j++) {
            //         if (this.selectAll.common_category[i].categoryId == e[j]) {
            //             arr[arr.length] = this.selectAll.common_category[i].categoryName
            //         }
            //     }


            // }
            // this.typeSelectList2 = arr






            // this.param.ypThingType = arr.join(",")

            // let arrayss = []
            // for (let i = 0; i < data.info.warehouseType.length; i++) {
            //     arrayss[arrayss.length] = data.info.warehouseType[i].tagId
            // }
            // this.param.warehouseType = arrayss.join(",")
            // this.wareSelect = arrayss
            // console.log(arrayss);

        },

        async xiajiaYes() {
            let data = await this.$api.base.askForRent(this.form)
            console.log(data);
            this.showXiajia = false
            this.rentList()
        },
        async xiajia(id, status) {
            this.form.id = id
            this.form.rentxinxiStatus = status
            if (status == 2) {
                this.showXiajia = true
                return
            }
            if (status == 1) {
                let data = await this.$api.base.askForRent(this.form)
                console.log(data);
                if (data == null) {
                    this.$message({
                        message: "上架成功",
                        type: 'success',
                        offset: 100
                    });
                }
                this.rentList()
            }
        },
        // 获取第一层省市区
        async getAreaSheng() {
            let data = await this.$api.base.getArea()
            // console.log(data);

            this.addressList = data.list
            // for (var i = 0; i < this.addressList.length; i++) {
            //     this.newProvinceDataList[0].push(this.addressList[i].name)
            // }
            // let arr1 = await this.getArea(this.addressList[0].id)
            // for (var i = 0; i < arr1.length; i++) {
            //     this.newProvinceDataList[1].push(arr1[i].name)
            //     this.arr1.push(arr1[i])
            // }
            // let arr2 = await this.getArea(arr1[0].id)
            // for (var i = 0; i < arr2.length; i++) {
            //     this.newProvinceDataList[2].push(arr2[i].name)
            // }


        },
        // 根据父级id获取市区
        async getArea(id) {
            let data = await this.$api.base.getArea({
                areaPid: id
            })
            // console.log(data.list);

            return data.list
            // this.addressList = data.list

        },
        // 获取初始化选项列表
        async getSelect() {
            let data = await this.$api.base.getSelect()
            console.log(data);
            this.selectAll = data.info

        },
        async deleteYes() {
            let data = await this.$api.base.askForRent(this.deleteParam)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "删除成功",
                    type: 'success',
                    offset: 100
                });
                this.showDelete = false
            }

            this.rentList()
        },
        shanchu(id) {
            this.deleteParam.id = id
            this.showDelete = true
        },
        handleClose() {
            this.fabuEdit = false
            nav.classList.remove("fixedNav");
        },

    },
};
</script>
<style scoped lang="less">
.ellipsis_1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}


/deep/ .el-dialog {

    border-radius: 10px !important;
}

.tab {
    /deep/ .el-tabs__nav-wrap {

        border-bottom: 1px solid #CCCCCC;
        padding-left: 20px;
    }
}

/deep/ .el-pagination .btn-next {
    // width: 20px !important;
    background: center center no-repeat #00B079;

}

/deep/ .el-pagination .btn-prev {
    background: center center no-repeat #00B079;


}


/deep/ .el-dialog__title {

    font-weight: 600;
}

/deep/ .el-pager li.active {
    color: #00B079;
    cursor: default;
}

/deep/ .el-pager li:hover {
    color: #00B079;
}

/deep/ .v-modal {
    z-index: 1000 !important;
}

.shenhe {
    .shenheimg {
        text-align: center;

        img {
            width: 200px;
            height: 200px;
        }
    }

    .tishi {
        div {
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #CCCCCC;
            line-height: 28px;
        }
    }

    .reason {

        width: 60%;
        margin: 0 auto;
        padding: 30px 50px;
        // height: 156px;
        background: #F0F0F0;
        border-radius: 10px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
    }

    .buttn {
        width: 260px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #00B079;
        border-radius: 10px 10px 10px 10px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin: 50px auto;
        cursor: pointer;
    }
}

.btnQueren {

    height: 48px;
    background: #00B079;
    border-radius: 10px 10px 10px 10px;

    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
}

.form_con {
    margin-top: 50px;
    // padding: 0 100px;

    .form_row {

        display: flex;
        // justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        // height: 42px;
        // margin-bottom: 30px;


        .every_form {
            // display: flex;
            // align-items: center;
            // flex-wrap: wrap;
            // justify-content: right;

            // width: 50%;


            .form_inp {
                width: 70%;
                cursor: pointer;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #00B079;

                span {
                    cursor: pointer;
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #00B079;
                }


            }

            span {
                margin-left: 10px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }


        }


    }
}

/deep/ .el-textarea__inner:focus {
    outline: 0;
    border-color: #00B079;
}

/deep/ .el-tabs__item.is-active {
    color: #00B079;
}

/deep/ .el-tabs__item {
    color: #999999 !important;
    font-size: 18px !important;
}

/deep/ .el-tabs__active-bar {

    background-color: #00B079;

}

/deep/ .el-tabs__item:hover {
    color: #00B079;
}

/deep/ .el-tabs__item {
    padding: 0px 24%;

}

.main {
    /deep/ .el-loading-spinner .path {

        stroke: #00B079 !important;
    }

    .flex_center_a {
        display: flex;
        align-items: center;

    }

    .flex_center_a_w {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .flex_between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .listData {
        // padding: 110rpx 30rpx;

        .every_data {
            background: #FFFFFF;
            padding: 30px 0;
            // border-radius: 20rpx;
            margin-bottom: 20px;
            border-bottom: 1px solid #CCCCCC;

            .data_title {
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                // margin-bottom: 12px;

                span {
                    color: #D00000;
                }
            }

            .data_mark1 {
                span {
                    font-size: 16px;
                    color: #999999;
                }

            }

            .data_list {
                width: 40%;
                margin-top: 12px;

                .every_list {
                    width: 50%;
                    font-size: 15px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 10px;

                    span {
                        font-size: 15px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        margin-bottom: 10px;

                        span {
                            font-size: 15px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .data_btn {
            margin-top: 20px;


            .btns {
                font-size: 28px;
                font-weight: bold;

                // color: #FFFFFF;
                .btn {
                    padding: 4px 20px;
                    border-radius: 100px;
                    margin-left: 10px;
                    cursor: pointer;
                }

                .btn1 {
                    background: #09B37E;
                    color: #FFFFFF;
                    border: 1px solid #09B37E;
                }

                .btn2 {
                    border: 1px solid #09B37E;
                    color: #09B37E;
                }

                .btn3 {
                    border: 1px solid #D00000;
                    color: #D00000;
                }
            }
        }
    }




}

.popup2 {
    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-textarea__inner {
        height: 100px !important;
    }

    .form_row {
        padding-left: 50px;

        display: flex;
    }

    // /deep/ .el-form-item {
    //     width: 50%;
    // }
}

/deep/ .el-textarea__inner {

    // font-size: 14px;

    font-family: auto;
}
</style>
